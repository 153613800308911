<template>
  <div v-if="!isLoading">
    <div :class="$style['container-bank-accounts']">
      <LayoutConnectAccounts
        :show-encryption-message="false"
        :show-error="error.show && !showManualBankWarning"
        :is-bank-connect-error="error.isBankConnectError"
        :is-bank-statements-error="error.isBankStatementsError"
        :event-tracking-fields="commonTrackingFields"
        :custom-banking-error="plaidErrorMessaging"
      >
        <Message v-if="showManualBankWarning" severity="warn" closable>
          There was an issue connecting your bank account via Plaid. Please
          enter your bank account details manually.
        </Message>

        <!-- titles and tooltips -->
        <template #titles>
          <h2 v-if="connectBankingTitle" :class="$style.heading">
            {{ connectBankingTitle }}
          </h2>
          <p :class="$style['sub-heading']">
            Connect
            <span v-if="!businessOnPlaidBypass && !showAddManualBankAccount"
              >with Plaid to link </span
            >your bank accounts and set a primary account. This primary account
            will be used to auto-debit your payments to Clearco and if
            applicable, deposit funding for receipts.
          </p>
        </template>
        <div
          v-if="!showSearchBankAccountScreen"
          ref="bank-account-messages"
          :style="{ scrollMargin: '20px' }"
        >
          <Message
            v-if="
              !isBankConnectionManagementEnabled &&
              BANK_IMPORTING_STATUSES.includes(selectedAccountStatus)
            "
            severity="success"
          >
            <p data-cy="connection-in-progress">
              Bank accounts linked. Account information may take 24-48 hrs to
              import.
            </p>
          </Message>
          <Message
            v-if="BANK_ERROR_STATUSES.includes(selectedAccountStatus)"
            severity="error"
            :closable="false"
          >
            <p data-cy="bank-connection-issue">
              There is an issue with your Plaid bank connection for your primary
              account.
              <span
                v-if="canRelinkSelectedBankAccount"
                data-cy="bank-connection-issue-relink-message"
                >For the applicable bank account click "{{ reconnectText }}” and
                follow the prompts.</span
              >
              For further troubleshooting instructions, please refer to our
              Plaid bank connection
              <a href="https://help.clear.co/s/Connect-a-bank-account-to-Plaid"
                >Help Center article</a
              >.
            </p>
          </Message>
          <Message
            v-else-if="
              selectedAccount &&
              !selectedAccountPassedPlaidChecks &&
              !selectedAccountDataIsImporting &&
              !showAddManualBankAccount
            "
            severity="error"
            :closable="false"
          >
            <p data-cy="plaid-checks-issue">
              There is insufficient banking data in your Plaid connected primary
              bank account. For further clarification, please refer to our Plaid
              bank connection
              <a href="https://help.clear.co/s/Connect-a-bank-account-to-Plaid"
                >FAQs</a
              >. If you have further questions, or require assistance
              establishing a Plaid bank connection, reach us at
              <a href="mailto:support@clear.co">support@clear.co</a> or
              1-(415)-610-5166 10am-6pm EST Monday to Friday
            </p>
          </Message>
        </div>
        <!--
          connect accounts via plaid
          ContainerConnectBankAccounts is here with v-show=false because the Link Bank Account uses the modal from
          this component but we don't want to show the Plaid image because  we also
          use the same plaid image from the TileGroupBankLinkConfirmation page. ideally we should remove the image
          from the other component and use the one from here
        -->
        <ContainerConnectBankAccounts
          v-show="false"
          ref="connectBanking"
          v-model:show-error="error.show"
          :event-tracking-fields="commonTrackingFields"
          @plaidSuccess="handlePlaidSuccess"
          @plaidError="handlePlaidError"
          @exit="plaidLoading = false"
        />
        <!-- bank account details -- adding manual bank account form -->
        <BankAccountDetails
          v-if="showAddManualBankAccount"
          :plaid-error-code="plaidErrorCode"
          :prefill-props="manualBankAccountPrefillProps"
          @success="handleManualAccountSuccess"
          @back="showAddManualBankAccount = false"
        />
        <!-- bank account search -->
        <BankAccountSearch
          v-if="
            (isBankConnectionManagementEnabled &&
              !shownBankAccounts?.length &&
              !businessOnPlaidBypass &&
              !showAddManualBankAccount) ||
            showSearchBankAccountScreen
          "
          @open-plaid-modal="(routingNumber) => openPlaidModal(routingNumber)"
          @show-add-manual-bank-account="addManualBankAccount"
        />
        <!-- link accounts-->
        <TileGroupBankLinkConfirmation
          v-if="!showAddManualBankAccount && !showSearchBankAccountScreen"
          ref="linkBanking"
          v-model:error="error"
          :event-tracking-fields="commonTrackingFields"
          @validationSuccess="handleValidationSuccess"
          @isPlaidLoading="
            (isLoading) => {
              plaidLoading = isLoading;
            }
          "
          @showContactSupportModal="showContactSupportModal"
          @showPrimaryChangeModal="showPrimaryChangeModal"
          @showActionRequiredModal="showActionRequiredModal"
          @bankAccountChange="bankAccountChange"
          @connect-new="handleConnectNew"
        />
        <div v-if="!showAddManualBankAccount" class="c-form">
          <div
            v-if="!isBankConnectionManagementEnabled"
            class="c-form__controls justify-center"
            :style="{ marginBottom: '50px' }"
          >
            <DSButton
              v-if="businessOnPlaidBypass"
              class="p-button-secondary"
              label="+ Connect Bank Account"
              @click="addManualBankAccount"
            />
            <DSButton
              v-else
              class="p-button-secondary"
              label="+ Link Bank Account"
              @click="() => openPlaidModal()"
            />
          </div>
          <div class="c-form__controls justify-center" :style="{ margin: '0' }">
            <DSButton
              class="p-button-outlined"
              :label="$t('common.buttons.back')"
              @click="
                $router.push({
                  name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
                })
              "
            />
            <DSButton
              data-cy="next-button"
              :disabled="
                !bankAccounts.length ||
                !selectedAccount ||
                (!selectedAccountPassedPlaidChecks &&
                  !selectedAccountDataIsImporting) ||
                (userHasEverHadActiveAdvance && !selectedAccountHasSignedAch) ||
                isLoadingRequest.isPending
              "
              :label="
                bypassPrimaryIsNotPlaidAccount
                  ? $t('common.buttons.next')
                  : $t('common.buttons.done')
              "
              @click="
                () => {
                  if (isBankConnectionManagementEnabled) {
                    done();
                  } else {
                    $refs.linkBanking.validate();
                  }
                }
              "
            />
          </div>
        </div>
        <Modal
          v-model:visible="primaryChangeModalIsVisible"
          header="Primary Bank Account Updated"
          :modal="true"
          :draggable="false"
          append-to="self"
          :style="{ maxWidth: '750px' }"
          :class="$style.modal"
        >
          <div>
            <div
              :class="$style.icon"
              v-html="require('@/assets/icons/icon-pen-and-paper.svg')"
            ></div>
            <p>
              You will be required to sign a new debit authorization agreement
              when your next invoice is approved, allowing Clearco to debit your
              weekly payments from this account. Any payments scheduled within
              the next 3 business days may be delayed while your account
              connects.
            </p>
            <p>
              Need help? Contact us at
              <a href="mailto:support@clear.co">support@clear.co</a> or
              1-(415)-610-5166 10am-6pm EST Monday to Friday.
            </p>
          </div>
          <template #footer>
            <DSButton
              label="Done"
              @click="
                () => {
                  if (isBankConnectionManagementEnabled) {
                    primaryChangeModalIsVisible = false;
                    return;
                  }

                  handleValidationSuccess();
                }
              "
            />
          </template>
        </Modal>
        <Modal
          v-model:visible="actionRequiredModalIsVisible"
          header="Action Required"
          modal
          :draggable="false"
          append-to="self"
          :style="{ maxWidth: '750px' }"
          :class="$style.modal"
        >
          <div
            :class="$style.icon"
            v-html="require('@/assets/icons/icon-pen-and-paper.svg')"
          ></div>
          <p>
            You are required to sign a debit authorization agreement allowing
            Clearco to debit your weekly payments from this account.
            <strong
              >We will only debit your account based on your weekly payment
              schedule after we’ve funded your bills.</strong
            >
            Any payments scheduled within the next 3 business days may be
            delayed while your account connects.
          </p>
          <p>
            Need help? Contact us at
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday.
          </p>
          <template #footer>
            <DSButton label="Sign ACH" @click="signContract" />
          </template>
        </Modal>
        <Modal
          v-model:visible="showSupportModal"
          header="Set another primary bank account"
          :modal="true"
          :draggable="false"
          append-to="self"
        >
          <div :class="$style['modal-content-container']">
            Please reach out to
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday to set this bank
            account as your new primary account.
          </div>
        </Modal>
      </LayoutConnectAccounts>
    </div>
  </div>
  <LoadingScreen v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Modal from '@clearbanc/clear-components/dialog';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';

import { LayoutConnectAccounts } from '@/components';
import TileGroupBankLinkConfirmation from '@/components/TileGroupBankLinkConfirmation';
import ContainerConnectBankAccounts from '@/components/ContainerConnectBankAccounts';
import LoadingScreen from '@/components/LoadingScreen';
import BankAccountSearch from '@/components/BankAccountSearch';
import addLocaleParam from '@/utils/add-locale-param';
import {
  BANK_ERROR_STATUSES,
  BANK_IMPORTING_STATUSES,
  BANK_CONNECTION_ACTIONS,
} from '@/data/bank-connection';
import BankAccountDetails from '@/components/BankAccountDetails';
import { useContextRoot } from '@/utils/context-root';
import contractUtils from '@/composables/contracts';
import analytics from '@/utils/analytics';

import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { UPDATED_PLAID_MESSAGING } from '@/data/plaid-error-codes';
import { LOCAL_STORAGE_KEYS } from '@/data/local-storage';

export default {
  components: {
    DSButton,
    ContainerConnectBankAccounts,
    LayoutConnectAccounts,
    TileGroupBankLinkConfirmation,
    LoadingScreen,
    Message,
    Modal,
    BankAccountDetails,
    BankAccountSearch,
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { signAchContract } = contractUtils(context, ctxRoot);
    return { signAchContract };
  },
  mixins: [stateModuleAware],
  data() {
    return {
      plaidLoading: false,
      isLoading: true,
      error: {
        show: false,
        isBankConnectError: false,
        isBankStatementsError: false,
      },
      BANK_ERROR_STATUSES,
      BANK_IMPORTING_STATUSES,
      PAYMENTS_ROUTE_NAMES,
      showSupportModal: false,
      primaryChangeModalIsVisible: false,
      actualSelectedAccount: null,
      showAddManualBankAccount: false,
      actionRequiredModalIsVisible: false,
      plaidErrorMessaging: '',
      showManualBankWarning: false,
      plaidErrorCode: null,
      showSearchBankAccountScreen: false,
      accountToUseInAchModal: null,
      manualBankAccountPrefillProps: null,
    };
  },
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'experimentName',
      'bankAccounts',
      'business',
      'businessProfileSteps',
      'isConnectBankingStepComplete',
      'isBankLinkConfirmationStepComplete',
      'currentBankAccountToBePrimary',
      'primaryBankAccount',
      'allBankAccountChecks',
      'isInFinancialDocsSegment',
      'businessOnPlaidBypass',
      'shownBankAccounts',
      'billContracts',
      'bankAccountContracts',
      'userHasEverHadActiveAdvance',
      'plaidErrorCodes',
      'isHeronPdfAutomationEnabled',
      'isBankConnectionManagementEnabled',
      'primaryIsPlaidAccount',
      'intendedPrimaryOrPrimaryBankAccount',
      'whitelistDetailsForRoutingNumber',
    ]),
    ...mapStateModuleGetters(['experimentName']),
    ...mapRequestStatuses({
      isLoadingRequest: ['SIGN_BANK_ACCOUNT_AUTH_FORM'],
    }),
    UPDATED_PLAID_MESSAGING: () => UPDATED_PLAID_MESSAGING,
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        experiment: this.experimentName,
        targetArea: this.$route.name,
      };
    },
    connectBankingTitle() {
      return (
        (this.showConnectAccounts || this.showLinkAccounts) &&
        this.businessProfileSteps[this.$route.name]?.label
      );
    },
    showConnectAccounts() {
      return !this.isConnectBankingStepComplete;
    },
    showLinkAccounts() {
      return !this.showConnectAccounts;
    },
    canRelinkSelectedBankAccount() {
      return [
        BANK_CONNECTION_ACTIONS.RELINK,
        BANK_CONNECTION_ACTIONS.CONNECT_NEW,
      ].includes(this.selectedAccount?.connection?.plaidStatus?.action);
    },
    selectedAccountHasSignedAch() {
      const combinedBankAccountAndBillContract = this.billContracts?.find(
        (billContract) =>
          billContract.bankAccountsId === this.selectedAccount?.id,
      );
      return (
        (this.bankAccountContracts &&
          !!this.bankAccountContracts[this.selectedAccount?.id]
            ?.userSignedAt) ||
        !!combinedBankAccountAndBillContract?.userSignedAt
      );
    },
    bypassPrimaryIsNotPlaidAccount() {
      return this.businessOnPlaidBypass && !this.primaryIsPlaidAccount;
    },
    selectedAccount() {
      return this.isBankConnectionManagementEnabled
        ? this.intendedPrimaryOrPrimaryBankAccount
        : this.actualSelectedAccount;
    },
    selectedAccountStatus() {
      if (this.businessOnPlaidBypass) {
        return null;
      }

      return this.selectedAccount?.connection?.plaidStatus?.summary;
    },
    selectedAccountDataIsImporting() {
      if (this.businessOnPlaidBypass) {
        return null;
      }

      return this.BANK_IMPORTING_STATUSES.includes(this.selectedAccountStatus);
    },
    selectedAccountPassedPlaidChecks() {
      if (this.businessOnPlaidBypass || this.selectedAccountDataIsImporting) {
        return true;
      }

      return this.allBankAccountChecks[this.selectedAccount?.id]
        ? this.allBankAccountChecks[this.selectedAccount?.id]?.allChecksPass
        : true;
    },
    reconnectText() {
      return this.isBankConnectionManagementEnabled
        ? 'Reauthorize Connection'
        : 'Relink account';
    },
  },
  async mounted() {
    if (
      this.$route.query.event === 'signing_complete' &&
      this.$route.query.contractId
    ) {
      await this.$store.dispatch('refreshContractStatus', {
        contractId: this.$route.query.contractId,
      });
      await this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
    }

    await Promise.all([
      this.$store.dispatchApiAction('GET_BANKS_ON_PLAID_WHITELIST'),
      this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS'),
      this.$store.dispatchApiAction('GET_PLAID_ERROR_CODES'),
    ]);

    this.plaidErrorCode = localStorage.getItem(
      LOCAL_STORAGE_KEYS.PLAID_ERROR_CODE,
    );

    if (this.businessOnPlaidBypass) {
      this.setManualAccountPrefillPropsByRouting(
        this.business.plaidBypass?.bank_id,
      );
    }

    // show the add manual bank account form when there are no bank accounts and the business is on plaid bypass
    // or when theyve encountered a plaid error
    this.showAddManualBankAccount = !!(
      (this.businessOnPlaidBypass && !this.shownBankAccounts.length) ||
      this.plaidErrorCode
    );

    this.isLoading = false;
  },
  methods: {
    addLocaleParam,
    done() {
      if (this.bypassPrimaryIsNotPlaidAccount) {
        const bankAccountId =
          this.primaryBankAccount?.id ?? this.currentBankAccountToBePrimary?.id;
        return this.$router.push({
          name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS,
          params: { id: bankAccountId },
        });
      }

      return this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
    async signContract() {
      this.actionRequiredModalIsVisible = false;
      await this.signAchContract(
        this.accountToUseInAchModal
          ? this.accountToUseInAchModal.id
          : this.selectedAccount.id,
      );
    },
    resetError() {
      this.plaidErrorMessaging = '';
      this.error = {
        show: false,
        isBankConnectError: false,
        isBankStatementsError: false,
      };
    },
    handleCtaClick() {
      this.resetError();
      if (this.showLinkAccounts) {
        this.$refs.linkBanking.validate();
      } else if (this.showConnectAccounts) {
        this.plaidLoading = true;
        this.$refs.connectBanking.openBankingIntegration();
      } else {
        this.plaidLoading = false;
        this.done();
      }
    },
    handlePlaidSuccess() {
      this.plaidLoading = false;
      if (!this.isConnectBankingStepComplete) {
        this.$refs.connectBanking.showPlaidPreview = true;
      }
      this.showSearchBankAccountScreen = false;
    },
    handlePlaidError(plaidMetadata) {
      if (this.isHeronPdfAutomationEnabled) {
        const errorRecord = this.plaidErrorCodes?.find((error) => {
          return error.plaidErrorCode === plaidMetadata.error_code;
        });

        if (errorRecord?.isEnabled) {
          this.plaidErrorCode = errorRecord.plaidErrorCode;
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.PLAID_ERROR_CODE,
            errorRecord.plaidErrorCode,
          );
          analytics.track('if_routed_to_manual_bank_page', {
            plaidErrorCode: errorRecord.plaidErrorCode,
          });
          this.showManualBankWarning = true;
          this.addManualBankAccount();
          this.plaidLoading = false;
          return;
        }

        this.plaidErrorMessaging =
          this.UPDATED_PLAID_MESSAGING[plaidMetadata.error_code];
      }
      this.showManualBankWarning = false;
      this.plaidLoading = false;
      this.error.show = true;
      this.error.isBankConnectError = true;
      this.$refs.connectBanking.showPlaidPreview = true;
      this.showSearchBankAccountScreen = false;
    },
    handleValidationSuccess() {
      if (this.isBankLinkConfirmationStepComplete) {
        this.done();
      } else {
        this.primaryChangeModalIsVisible = false;
      }
    },
    handleManualAccountSuccess() {
      this.showAddManualBankAccount = false;
      this.plaidErrorMessaging = '';
      this.plaidErrorCode = null;
      this.error.show = false;
      this.error.isBankConnectError = false;
      this.showSearchBankAccountScreen = false;
    },
    showContactSupportModal() {
      this.showSupportModal = true;
    },
    showPrimaryChangeModal() {
      this.primaryChangeModalIsVisible = true;
    },
    showActionRequiredModal(account) {
      if (account) {
        this.accountToUseInAchModal = account;
      }

      this.actionRequiredModalIsVisible = true;
    },
    openPlaidModal(routingNumber) {
      this.resetError();
      this.$refs.connectBanking.openBankingIntegration(routingNumber);
    },
    bankAccountChange(clickedBankAccount) {
      this.$nextTick(this.scrollToMessages);

      this.actualSelectedAccount = this.bankAccounts.find(
        (account) => account.id === clickedBankAccount,
      );
    },
    areChildNodesCommentsOnly(element) {
      const childNodes = element.childNodes;

      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].nodeType !== Node.COMMENT_NODE) {
          return false;
        }
      }

      return true;
    },
    scrollToMessages() {
      const messagesWrapper = this.$refs['bank-account-messages'];
      const messagesWrapperRect = messagesWrapper.getBoundingClientRect();

      if (
        !messagesWrapper.hasChildNodes() ||
        this.areChildNodesCommentsOnly(messagesWrapper)
      ) {
        return;
      }

      const isInViewport =
        messagesWrapperRect.top >= 0 &&
        messagesWrapperRect.left >= 0 &&
        messagesWrapperRect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        messagesWrapperRect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (!isInViewport) {
        messagesWrapper.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    addManualBankAccount(routingNumber) {
      if (routingNumber) {
        this.setManualAccountPrefillPropsByRouting(routingNumber);
      }

      this.showAddManualBankAccount = true;
      this.showSearchBankAccountScreen = false;
    },
    handleConnectNew() {
      if (this.businessOnPlaidBypass) {
        this.addManualBankAccount();
      } else {
        this.showSearchBankAccountScreen = true;
      }
    },
    setManualAccountPrefillPropsByRouting(routingNumber) {
      const details = this.whitelistDetailsForRoutingNumber(routingNumber);
      if (details) {
        this.manualBankAccountPrefillProps = {
          routingNumber: details?.routingNumber,
          bankName: details?.name,
          countryCode: 'US',
          currencyCode: 'USD',
        };
      }
    },
  },
};
</script>

<style>
.justify-center {
  justify-content: center !important;
}
</style>

<style lang="less" module>
.explainer-text {
  max-width: 615px;
}

.footer-container {
  width: 100%;
  max-width: 1000px;
  padding: 0 50px;
  margin: auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @media screen and (min-width: 961px) {
    transform: translateY(-25px);
  }
  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 550px) {
    flex-flow: column;
    align-items: center;
  }

  .encryption-container {
    color: @cc-light-brown;
    display: flex;
    align-items: baseline;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      fill: @cc-light-brown;
      transform: translateY(2px);
      margin-right: 5px;
    }
    a {
      margin-left: 5px;
    }
  }
}

.connect-account-btn {
  button {
    background-color: @color-cc-purple-200;
    &:hover {
      background-color: @color-cc-purple-200 !important;
    }
  }
}

h2.title {
  font-size: 19px;
}

h2.heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 24px;
}

.sub-heading {
  margin: 0 auto 2rem;
  max-width: 550px;
}

.modal-content-container {
  max-width: 700px;
  text-align: left;
}

@media screen and (max-width: 500px) {
  h2.heading,
  .sub-heading {
    text-align: left;
  }
}

.modal {
  .icon {
    width: 100%;
    max-width: 77px;
    margin: 47px auto;
  }

  [class='p-dialog-footer'] button {
    margin: 0;
  }

  [class='p-dialog-content'] {
    padding-bottom: 2rem;
  }
}
</style>
